<template>
    <div class="inputRow">
      <StaticTable class="table" v-if="lineInfo.Heading == 'Measurements'" />
        <div v-if="lineInfo.Heading !== 'Measurements'" class="d-flex inputLabel text-right justify-content-end">
            <SectionHeading v-if="lineInfo.Heading" :markup= "lineInfo.Markup" :heading = "lineInfo.Heading" :units="lineInfo.Units"/>
        </div>
        <p v-if="lineInfo.Input_Types && lineInfo.Heading === 'Size Range'">{{savedSizeRange}}</p>
        <p v-if="lineInfo.Input_Types && lineInfo.Input_Types != 'upload' && lineInfo.Heading !== 'Size Range'">{{lineInfo.Value}}</p>
        <!-- <img v-if="lineInfo.Input_Types == 'upload'" type="file" id="myFile" name="filename" @input="getFile"> -->
        <Images v-if="lineInfo.fileName" :imageCode='lineInfo.fileName'/>
        <p v-if="lineInfo.Heading == 'Designer'">{{garmentDesigner}}</p>
        <p v-if="lineInfo.Heading == 'Stage'">{{garmentStage}}</p>

    </div>
</template>

<script>
import SectionHeading from '../SectionHeading.vue'
import Images from '../../components/factory/Images.vue'
import StaticTable from './StaticTable.vue'

export default {
  name: 'ViewLine',
  props: ['index', 'lineIndex'],
  components: {
    SectionHeading,
    Images,
    StaticTable
  },
  methods: {
    getFile (event) {
      this.$store.commit('addFile', event.target.files[0])
    },
    getInputType () {
      return this.lineInfo.Input_Types
    },
    updateValue (value) {
      if (this.lineInfo.Heading === 'Size Range') {
        this.$store.commit('setActiveSizeRange', value.target.value)
        this.$store.commit('updateSections', [value.target.value, this.index, this.lineIndex])
      } else {
        this.$store.commit('updateSections', [value.target.value, this.index, this.lineIndex])
      }
    }
  },
  computed: {
    garmentDesigner () {
      return this.$store.state.selectedGarment.designer_email
    }, 
    garmentStage () {
      return this.$store.state.selectedGarment.garmentStage
    },  
    savedSizeRange () {
      return this.$store.state.activeSizeRange
    },
    sizeRanges () {
      const sizeRanges = []
      const sizeRangeInfo = this.$store.getters.getSizeRanges
      for (let i = 0; i < sizeRangeInfo.length; i++) {
        sizeRanges.push(sizeRangeInfo[i].sizeRangeName)
      }
      return sizeRanges
    },
    inputSize () {
      switch (this.lineInfo.Input_length) {
        case 'short':
          return 12
        case 'long':
          return 60
        case 'mid':
          return 30
        default:
          return 20
      }
    },

    lineInfo: {
      get () {
        return this.$store.state.sections[this.index][this.lineIndex]
      },
      set (value) {
        this.$store.commit('updateSections', [value, this.index, this.lineIndex])
      }
    }
  }
}
</script>

<style>

form.form-example {
    display: table;
}

div.form-example {
    display: table-row;
}

input, textarea {
    margin-bottom: 5px;
    margin-left: 10px;
}

label {
    padding-right: 10px;
}

.inputRow {
    display: flex;
}

.inputLabel {
    flex: 0 0 230px;
}

</style>
