import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Debug from '../views/Debug.vue'

import CreateGarment from '../views/CreateGarment.vue'
import SelectTemplate from '../views/SelectTemplate.vue'
import SelectGarment from '../views/SelectGarment.vue'
import EditGarment from '../views/EditGarment.vue'
import ViewGarment from '../views/factory-views/ViewGarment.vue'
import SelectGarmentToView from '../views/factory-views/SelectGarmentToView.vue'
import FactoryDashboard from '../views/factory-views/FactoryDashboard.vue'
import { store } from '../store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumbs: [
        { text: 'Home' }
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/debug',
    name: 'Debug',
    component: Debug,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
      ]
    }
  },
  {
    path: '/create_garment',
    name: 'CreateGarment',
    component: CreateGarment,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select template', to: '/select_template' },
        { text: 'create garment' }
      ]
    }
  },
  {
    path: '/select_template',
    name: 'SelectTemplate',
    component: SelectTemplate,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select template' }
      ]
    }
  },
  {
    path: '/select_garment',
    name: 'SelectGarment',
    component: SelectGarment,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select garment' }
      ],
      factoryBreadcrumbs: [
        { text: 'Dashboard', to: '/factory_dashboard' },
        { text: 'select garment' }
      ]
    }
  },
  {
    path: '/edit_garment',
    name: 'EditGarment',
    component: EditGarment,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select garment', to: '/select_garment' },
        { text: 'edit garment' }
      ]
    }
  },
  {
    path: '/view_garment',
    name: 'ViewGarment',
    component: ViewGarment,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select garment to view', to: '/select_garment_to_view' },
        { text: 'view garment' }
      ],
      factoryBreadcrumbs: [
        { text: 'Dashboard', to: '/factory_dashboard' },
        { text: 'select garment to view', to: '/select_garment_to_view' },
        { text: 'view garment' }
      ]
    }
  },
  {
    path: '/select_garment_to_view',
    name: 'SelectGarmentToView',
    component: SelectGarmentToView,
    meta: {
      breadcrumbs: [
        { text: 'Home', to: '/' },
        { text: 'select garment to view' }
      ],
      factoryBreadcrumbs: [
        { text: 'Dashboard', to: '/factory_dashboard' },
        { text: 'select garment to view' }
      ]
    }
  },
  {
    path: '/factory_dashboard',
    name: 'FactoryDashboard',
    component: FactoryDashboard,
    meta: {
      factoryBreadcrumbs: [
        { text: 'Factory dashboard' }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    }
  }
})

const designerOnlyViews = ['Signup', 'CreateGarment', 'SelectTemplate', 'EditGarment']

router.beforeEach((to, from, next) => {
  // reset store.sections 

  store.state.sections = []
  if (designerOnlyViews.includes(to.name) && store.state.userGroup === 'factoryusergroup') {
    next({ name: 'FactoryDashboard' })
  } else {
    next()
  }
})

export default router
