import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Auth } from 'aws-amplify'
Vue.use(Vuex)

const techpackServiceAPIEndpoint = process.env.VUE_APP_TECHPACK_API_ENDPOINT
const templateServiceAPIEndpoint = process.env.VUE_APP_TEMPLATE_API_ENDPOINT

function handleError (err) {
  console.error(err)
}

// Read templatesList
async function loadTemplates (state) {
  const url = templateServiceAPIEndpoint + '/templates'
  const session = await Auth.currentSession()
  const bearerToken = session.getIdToken().getJwtToken()
  const res = await axios.get(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  })
  const templateList = JSON.parse(res.data.body)
  state.templatesList = templateList.templates.Items
}

// Read json template into the store on creation of app
function getTemplate (state) {
  let i = 0
  for (i; i < state.templatesList.length; i++) {
    if (state.templatesList[i].templateName === state.selectedTemplate) {
      state.template = state.templatesList[i]
      state.sections = state.templatesList[i].form
      state.measurements = state.templatesList[i].measurements
    }
  }
}

function setTemplate (state, payload) {
  const template = payload
  state.selectedTemplate = template
  getTemplate(state)
}

export const store = new Vuex.Store({
  state: {
    chimeChannelArn: '',
    techpackServiceAPIEndpoint: techpackServiceAPIEndpoint,
    userGroup: '',
    sections: [],
    measurements: [],
    template: [],
    selectedTemplate: '',
    templatesList: [],
    garmentList: [],
    selectedGarment: '',
    loggedIn: false,
    activeSizeRange: '',
    files: [],
    fileNames: [],
    deletedFiles: [],
    assignedFactory: '',
    garmentStage: 'draft',
    failedRequiredFields: [],
    sendEmailtoFactory: false,
  },

  getters: {

    getUserGroup (state) {
      return state.userGroup
    },

    getActivesizeRange (state) {
      return state.activeSizeRange
    },

    getEmptySection: (state) => (sectionIndex) => {
      return state.template.form[sectionIndex] // uses template! This causes extendible form to break when no template is loaded (if user called edit form)
    },

    getSectionHeading: (state) => (sectionIndex) => {
      return state.sections[sectionIndex][0].Heading
    },

    getEmptySubSection: (state) => (sectionIndex) => {
      const blankSection = state.template.form[sectionIndex]
      const blankSubSection = []
      var subTitleNumber = 0
      for (const line of blankSection) {
        if (subTitleNumber === 1 && line.Markup === 'subtitle') {
          return blankSubSection
        }
        if (line.Markup === 'subtitle' && subTitleNumber === 0) {
          blankSubSection.push(line)
          subTitleNumber++
        }
        if (subTitleNumber === 1 && line.Markup !== 'subtitle') {
          blankSubSection.push(line)
        }
      }
      return blankSubSection
    },

    getTemplateIds (state) {
      const templateIds = []
      state.templatesList.forEach((item) => {
        templateIds.push(item.templateIds)
      })
      return templateIds
    },

    /**
     *
     * @param {*} state
     * @returns array of sizeRange arrays
     */
    getSizeRanges (state) {
      const sizeRanges = []
      const measurements = state.measurements
      for (let i = 0; i < measurements.length; i++) {
        if (measurements[i].AreasOnDrawing === 'size_ranges') {
          sizeRanges.push(measurements[i])
        }
      }
      return sizeRanges
    }
  },

  mutations: {

    updateSendEmailtoFactory: (state, value) => {
      state.sendEmailtoFactory = value
    },

    resetChimeChannelArn: (state) => {
      state.chimeChannelArn = ""
    },

    resetLocalState: async (state) => {
      state.sections = [],
      state.measurements = [],
      state.template = [],
      state.selectedTemplate = '',
      state.loggedIn = false,
      state.activeSizeRange = '',
      state.files = [],
      state.fileNames = [],
      state.deletedFiles = [],
      state.assignedFactory = '',
      state.garmentStage = 'draft',
      state.failedRequiredFields = []
    },

    callLoadTemplates: async (state) => {
      await loadTemplates(state)
    },

    callSetTemplate: (state, payload) => {
      setTemplate(state, payload)
    },

    resetAssignedFactory: (state) => {
      state.assignedFactory = ''
    },

    addRowToTable: (state, payload) => {
      state.measurements.push({})
    },

    setGarmentStage: (state, payload) => {
      state.garmentStage = payload
    },

    setAssignedFactory: (state, payload) => {
      state.assignedFactory = payload
    },

    deleteImage: (state, payload) => {
      console.log(payload)
      const sectionIndex = payload[1]
      const lineIndex = payload[2]

      state.deletedFiles.push(payload[0])
      state.sections[sectionIndex][lineIndex].fileName = false
    },

    addFile: (state, payload) => {
      state.files.push({
        file: payload[0],
        sectionIndex: payload[1],
        lineIndex: payload[2]
      })
    },

    setUserGroup: (state, usergroup) => {
      state.userGroup = usergroup
    },

    logIn: (state, usergroup) => {
      state.loggedIn = true
      state.userGroup = usergroup
    },

    logOut: (state) => {
      state.loggedIn = false
    },

    updateMeasurements: (state, payload) => {
      // payload is [value, index, field]
      const value = payload[0]
      const index = payload[1]
      const field = payload[2]
      // console.log(state.measurements.length)
      // if (state.measurements.length <= index) {
      //   state.measurements.push({})
      // }
      state.measurements[index][field] = value
    },

    updateSections: (state, payload) => {
      // payload is [val, idx, lineidx]
      const value = payload[0]
      const index = payload[1]
      const lineIndex = payload[2]
      state.sections[index][lineIndex].Value = value
    },

    addSectionLine: (state, payload) => {
      const line = payload[0]
      const sectionIndex = payload[1]
      state.sections[sectionIndex].push(line)
    },

    // Read garments list
    async loadGarments (state) {
      const userId = await getUserId()
      const user = await Auth.currentAuthenticatedUser()
      const group = user.signInUserSession.accessToken.payload['cognito:groups'][0]
      let userType
      if (group === 'factoryusergroup') {
        userType = 'factory/'
      } else {
        userType = 'designer/'
      }
      const url = techpackServiceAPIEndpoint + '/' + userType + userId
      axios.get(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      })
        .then(res => {
          console.log(res)
          const garmentList = JSON.parse(res.data.body)
          state.garmentList = garmentList.techpacks.Items
        })
        .catch(err => {
          handleError(err)
        })
    },

    addListItem (state, metaData) {
      // metaData = [name, contents, index]
      const name = metaData[0]
      const contents = metaData[1]
      const index = metaData[2]

      state[name] = {
        index: index, // the section index the item belongs to
        contents: contents
      }
    },

    setActiveSizeRange (state, value) {
      state.activeSizeRange = value
    }

  },
  actions: {

    async setGarment (context, garmentId) {
      context.state.selectedGarment = garmentId
      let i = 0
      for (i; i < context.state.garmentList.length; i++) {
        if (context.state.garmentList[i].id === garmentId) {
          const templateName = context.state.garmentList[i].template_name
          await loadTemplates(context.state)
          setTemplate(context.state, templateName)
          context.state.selectedGarment = context.state.garmentList[i]
          context.state.sections = context.state.garmentList[i].form_data
          context.state.measurements = context.state.garmentList[i].measurements_data
          context.state.activeSizeRange = context.state.garmentList[i].size_range
          context.state.assignedFactory = context.state.garmentList[i].assignedFactoryEmail
          context.state.chimeChannelArn = context.state.garmentList[i].chimeChannel
        }
      }
    },

    async postDesign (context) {
      // reset required fields list to empty
      context.state.failedRequiredFields = []
      if (!verifyRequiredFields()) {
        return
      }
      for (let i = 0; i < context.state.files.length; i++) {
        const file = context.state.files[i]
        const randKey = parseInt(Math.random() * 10000000)
        console.log({ key: randKey })
        const fileType = await uploadImage(i, randKey)
        console.log({ filetypeFromUpload: fileType })
        context.state.fileNames.push(randKey + fileType)
        console.log({ fullFileName: randKey + fileType })
        context.state.sections[file.sectionIndex][file.lineIndex].fileName = randKey + fileType
      }
      const garmentId = uuidv4()

      let techpack = {
        garment_id: garmentId,
        designer_id: await getUserId(),
        designer_email: await getUserEmail(),
        factory_id: '',
        version_no: 1,
        is_latest: true,
        template_name: context.state.template.templateName,
        template_version: context.state.template.version_no,
        sections: context.state.sections,
        measurements: context.state.measurements,
        size_range: context.state.activeSizeRange,
        name: context.state.sections[0][1].Value,
        code: context.state.sections[0][2].Value,
        date_created: new Date().toISOString(),
        fileNames: context.state.fileNames,
        assignedFactory: context.state.assignedFactory,
        garmentStage: context.state.garmentStage,
        season: context.state.sections[0][5].Value,
        sendEmailtoFactory: context.state.sendEmailtoFactory
      }
      console.log({ techpack: techpack })

      techpack = JSON.stringify(techpack)
      const url = techpackServiceAPIEndpoint

      return axios.post(url, techpack, {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }).then(res => console.log(res)).catch(function (error) {
        handleError(error)
        if (error.response) {
          console.log(error.response.data)
          throw new Error(JSON.stringify(error.response.data))
        }
      })
    },

    async updateTechpack (context) {
      // reset required fields list to empty
      context.state.failedRequiredFields = []
      if (!verifyRequiredFields()) {
        return
      }
      for (let i = 0; i < context.state.files.length; i++) {
        const file = context.state.files[i]
        const randKey = parseInt(Math.random() * 10000000)
        console.log({ key: randKey })
        const fileType = await uploadImage(i, randKey)
        console.log({ filetypeFromUpload: fileType })
        context.state.fileNames.push(randKey + fileType)
        console.log({ fullFileName: randKey + fileType })
        context.state.sections[file.sectionIndex][file.lineIndex].fileName = randKey + fileType
      }
      let techpack = context.state.selectedGarment
      techpack.date_last_updated = new Date().toISOString()
      techpack.measurements_data = context.state.measurements
      techpack.form_data = context.state.sections
      techpack.name = context.state.sections[0][1].Value
      techpack.code = context.state.sections[0][2].Value
      techpack.size_range = context.state.activeSizeRange
      techpack.fileNames = context.state.fileNames
      techpack.deletedFiles = context.state.deletedFiles
      techpack.assignedFactory = context.state.assignedFactory
      techpack.garmentStage = context.state.garmentStage
      techpack.season = context.state.sections[0][5].Value
      techpack.template_name = context.state.selectedGarment.template_name
      techpack.chimeChannel = context.state.selectedGarment.chimeChannel
      techpack.designer_email = await getUserEmail()
      techpack.sendEmailtoFactory = context.state.sendEmailtoFactory


      const url = techpackServiceAPIEndpoint + '/' + techpack.id
      console.log({ techpack: techpack })
      techpack = JSON.stringify(techpack)


      return axios.patch(url, techpack, {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }).then(res => console.log(res)).catch(function (error) {
        handleError(error)
        if (error.response) {
          console.log(error.response.data)
          throw new Error(JSON.stringify(error.response.data))
        }
      })
    },

    addNewSectionItem (context, payload) {
      const newSection = payload[0]
      const sectionIndex = payload[1]
      const fileData = payload[2] // [file, sectionIndex, lineIndex]
      console.log(newSection)
      newSection.forEach(function (line) {
        const payload = [line, sectionIndex]
        // call addfile with fileData but wiht updatedd lineIndex for section
        context.commit('addSectionLine', payload)
        if (line.isFile) {
          // updatedLineIndex needed because origional lineIndex based on subsection
          const updatedLineIndex = context.state.sections[sectionIndex].length - 1
          const updatedFileData = [fileData[0], sectionIndex, updatedLineIndex]
          context.commit('addFile', updatedFileData)
          console.log('adding file')
        }
      })
    }
  }
})

async function getUserId () {
  const user = await Auth.currentAuthenticatedUser()
  return user.username
}

function verifyRequiredFields () {
  const sections = store.state.sections
  const failedRequirementsList = []
  for (const section of sections) {
    for (const row of section) {
      if (row.Required && (!row.Value || row.Value === '')) {
        failedRequirementsList.push({
          sectionHeading: section[0].Heading,
          requirementHeading: row.Heading
        })
      }
    }
  }
  store.state.failedRequiredFields = failedRequirementsList
  if (failedRequirementsList.length !== 0) {
    return false
  }
  return true
}

async function uploadImage (i, key) {
  console.log('uploading image ' + i)
  console.log({ imageKey: key })
  const imageFile = store.state.files[i].file
  console.log({ imageFileType: imageFile.type }) // https://developer.mozilla.org/en-US/docs/Web/API/File/type - Limited support on mobile browsers
  const putSignedURL = techpackServiceAPIEndpoint + '/s3/putSignedURL/'
  let fileType = '.jpg'
  if (imageFile.type === 'image/png') {
    fileType = '.png'
  }
  axios.get(putSignedURL + key.toString() + fileType, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  })
    .then(async res => {
      const resData = JSON.parse(res.data)
      console.log({ signedURL: resData })
      const response = await fetch(resData.uploadURL, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': imageFile.type
        },
        method: 'PUT',
        body: imageFile
      })
      console.log(response)
    })
    .catch(err => handleError(err))
  return fileType
}

async function getUserEmail () {
  const response = await Auth.currentSession()
  return response.idToken.payload.email
}
