<template>
  <div class='table-container'>
    <h2 class="text-center">Measurements</h2>
    <b-table striped hover :items="getMeasurements" :fields='fields' small ></b-table>
  </div>
</template>

<script>

export default {
  name: 'StaticTable',

  computed: {
    activeSizeRange () {
      const activeSizeRangeName = this.$store.getters.getActivesizeRange
      let i = 0
      for (i; i < this.sizeRanges.length; i++) {
        if (this.sizeRanges[i].sizeRangeName === activeSizeRangeName) {
          return i
        }
      }
      return 0
    },
    sizeRanges () {
      return this.$store.getters.getSizeRanges
    },
    getMeasurements () {
      const measurements = this.$store.state.measurements
      const newMeasurements = []
      for (let i = 0; i < measurements.length; i++) {
        if (measurements[i].AreasOnDrawing !== 'size_ranges') {
          newMeasurements.push(measurements[i])
        }
      }
      return newMeasurements
    },
    fields () {
      let numberOfSizes = 0
      Object.keys(this.sizeRanges[0]).forEach(function (key) {
        if (!['sizeRangeName', 'AreasOnDrawing'].includes(key)) {
          numberOfSizes += 1
        }
      })

      const fields = [
        { key: 'AreasOnDrawing', label: 'Area on drawing' },
        { key: 'AreasInCm', label: 'Area' },
        { key: 'Description', label: 'Description', editable: true },
        { key: 'Tolerance', label: 'Tolerance (cm)', editable: true }
      ]

      for (let i = 1; i <= numberOfSizes; i++) {
        const str = i.toString()
        const newSize = 'size_' + str
        const newField = {
          key: newSize,
          label: this.sizeRanges[this.activeSizeRange][newSize],
          tdClass: 'inputBox',
          editable: true
        }
        fields.push(newField)
      }
      return fields
    }
  }
}

</script>

<style>

/* .table-container {
  padding: 0px 75px 0px 75px;
} */

</style>
