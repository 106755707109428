<template>
  <div :style="{background: backgroundColor}">
    <beautiful-chat
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen"
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :on-message-was-sent="onMessageWasSent"
      :open="openChat"
      :participants="participants"
      :show-close-button="true"
      :show-launcher="true"
      :show-emoji="false"
      :show-file="false"
      :show-typing-indicator="showTypingIndicator"
      :show-edition="false"
      :show-deletion="false"
      :title-image-url="titleImageUrl"
      :disable-user-list-toggle="false"
      @onType="handleOnType"

    >
      <template v-slot:header>
        Chat between {{participantsWithoutAdmin().map(m=>m.name).join(' & ')}}
      </template>

      <template v-slot:text-message-body="scopedProps">
        <p class="sc-message--text-content chatmessages" v-html="scopedProps.messageText"></p>
        <p
          v-if="scopedProps.message.data.meta"
          class="sc-message--meta"
          :style="{color: scopedProps.messageColors.color}"
        >
          {{ scopedProps.message.data.meta }}
        </p>
        <p
          v-if="scopedProps.message.isEdited || scopedProps.message.liked"
          class="sc-message--edited"
        >
          <template v-if="scopedProps.message.isEdited">✎</template>
          <template v-if="scopedProps.message.liked">👍</template>
        </p>
      </template>
      <template v-slot:system-message-body="{message}"> [System]: {{ message.text }} </template>
    </beautiful-chat>

  </div>
</template>

<script>
import * as chimeWebsockets from '../chime-websockets' // Initialise Chime websockets & observer

import * as chimeAPI from '../chime-api.js'
import { Auth } from 'aws-amplify'

export default {
  name: 'ChatWindow',
  data () {
    return {
      participants: [],
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: '',
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      availableColors: {
        blue: {
          header: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          launcher: {
            bg: '#4e8cff'
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          receivedMessage: {
            bg: '#eaeaea',
            text: '#222222'
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867'
          },
          userList: {
            bg: '#fff',
            text: '#212121'
          }
        }
      },
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false
    }
  },
  computed: {
    chimeChannel () {
      return this.$store.state.chimeChannelArn
    },
    linkColor () {
      return this.chosenColor === 'dark' ? this.colors.sentMessage.text : this.colors.launcher.bg
    },
    backgroundColor () {
      return this.chosenColor === 'dark' ? this.colors.messageList.bg : '#fff'
    }
  },

  async created () {
    const credentials = await Auth.currentCredentials()
    const userDetails = await Auth.currentAuthenticatedUser()
    const userId = userDetails.attributes.sub

    console.log("Setting channel participants")
    await this.setChannelParticipants()
    
    console.log("Connecting to chime websockets")
    chimeWebsockets.connect(this.onMessage, credentials, userId)
    this.setColor('blue')

    console.log("Listing channel messages")
    this.messageList = await this.listMessages()
  },

  methods: {
    participantsWithoutAdmin () {
      const participantsWithoutAdmin = this.participants.filter(m => m.name !== 'Admin')
      return participantsWithoutAdmin
    },

    async onMessage (message) {
      const incomingMessage = JSON.parse(message.payload)
      console.log({incomingMessage: incomingMessage})
      

      const userDetails = await Auth.currentAuthenticatedUser()
      const currentUserARN = process.env.VUE_APP_CHIME_APP_INSTANCE_ARN + '/user/' + userDetails.attributes.sub

      if (incomingMessage != null && incomingMessage.ChannelArn === this.chimeChannel) {
        const newMessage = {
          author: incomingMessage.Sender.Arn,
          data: {
            text: incomingMessage.Content
          },
          id: incomingMessage.MessageId,
          type: 'text'
        }
        console.log({
          newMessageAuthor: newMessage.author,
          currentUserArn: currentUserARN
        })
        if (newMessage.author === currentUserARN) {
          newMessage.author = 'me'
        }
        this.messageList.push(newMessage)
      }
    },

    async setChannelParticipants () {
      const participants = []
      if (this.chimeChannel) {
        console.log({chimechannel: this.chimeChannel})
        const members = await chimeAPI.listChannelMemberships(this.chimeChannel)
        for (const member of members) {
          const newMember = { id: member.Member.Arn, name: member.Member.Name }
          participants.push(newMember)
        }
      }
      this.participants = participants
    },

    async listMessages () {
      const userDetails = await Auth.currentAuthenticatedUser()
      const currentUserARN = process.env.VUE_APP_CHIME_APP_INSTANCE_ARN + '/user/' + userDetails.attributes.sub

      const messageList = []
      if (this.chimeChannel) {
        const messages = await chimeAPI.listChannelMessages(this.chimeChannel)
        messages.Messages.forEach(message => {
          const newMessage = {
            type: 'text',
            author: message.Sender.Arn,
            id: message.MessageId,
            data: { text: message.Content }
          }
          if (newMessage.author === currentUserARN) {
            newMessage.author = 'me'
          }
          messageList.push(newMessage)
        })
      }
      return messageList
    },

    async onMessageWasSent (message) {
      console.log(await chimeAPI.sendChannelMessage(message.data.text, this.chimeChannel))
    },

    openChat () {
      this.isChatOpen = true
      this.newMessagesCount = 0
    },

    closeChat () {
      this.isChatOpen = false
    },

    setColor (color) {
      this.colors = this.availableColors[color]
      this.chosenColor = color
    },

    messageStylingToggled (e) {
      this.messageStyling = e.target.checked
    },
    
    handleOnType () {
      this.$root.$emit('onType')
      this.userIsTyping = true
    }
  }
}
</script>

<style scoped>
.chatmessages {
  text-align: left;
  margin-bottom: 0.2rem;
}
</style>