<template>
    <div>
        <ViewData />
        <!-- <Images v-for='(image, index) in listOfImages' :key='index' :imageCode='image'/> -->
        <ChatWindow v-if='chimeChannel.length > 0' />
    </div>

</template>

<script>
import StaticTable from '../../components/factory/StaticTable.vue'
import ViewData from '../../components/factory/ViewData.vue'
import Images from '../../components/factory/Images.vue'
import ChatWindow from '../../components/ChatWindow.vue'

export default {
  components: {
    StaticTable,
    ViewData,
    Images,
    ChatWindow
  },
  computed: {
    listOfImages () {
      return this.$store.state.selectedGarment.fileNames
    },
    chimeChannel () {
      return this.$store.state.chimeChannelArn
    },
  }
}
</script>
