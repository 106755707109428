<template>
  <div>
    <b-form-checkbox
      id="checkbox-1"
      v-model="sendEmailtoFactory"
      name="checkbox-1"
      value=true
      unchecked-value=false
    >
      Notify factory of update
    </b-form-checkbox>

  </div>
</template>

<script>
  export default {
    name: 'MainForm',
    computed: {
      sendEmailtoFactory: {
        get () {
          return this.$store.state.sendEmailtoFactory
        },
        set (value) {
          let booleanValue
          if (value === "true") {
            booleanValue = true
          }
          else {
            booleanValue = false
          }
          this.$store.commit('updateSendEmailtoFactory', booleanValue)

        }
      }
    }
  }
</script>