<template>
  <div>
      <img class='image' :src='imageURL' style="display:block">

    <br>
  </div>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'

export default {
  name: 'Images',
  props: ['imageCode'],
  data () {
    return {
      imageURL: ''
    }
  },
  computed: {
    techpackServiceAPIEndpoint () {
      const APIEndpoint = this.$store.state.techpackServiceAPIEndpoint
      return APIEndpoint
    }
  },
  async created () {
    const getSignedURL = this.techpackServiceAPIEndpoint + '/s3/getSignedURL/' + this.imageCode.toString()
    await axios.get(getSignedURL, {
      method: 'GET', 
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })
      .then(res => {
        const resData = JSON.parse(res.data)
        this.imageURL = resData.objectURL
      })
      .catch(err => console.log(err))
  }

}
</script>

<style scoped>

</style>