<template>
    <div id="signup">
        <b-form v-on:submit.prevent="handleSubmit">

            <b-form-group  id="email-input" label="Email" >
                <b-form-input autoFocus type="email" value="email" v-model="email" required></b-form-input>
            </b-form-group>

            <b-form-group id="password-input" label="Password">
                <b-form-input type="password" v-model="password" required></b-form-input>
            </b-form-group>

            <b-form-group id="confirm-password-input" label="Conform password">
                <b-form-input type="password" v-model="confirmPassword" required></b-form-input>
            </b-form-group>

            <b-button block variant="primary" type="submit" :disabled="isFormInvalid">Log in</b-button>
        </b-form>

        <b-modal id="confirmSignup">
            <b-form v-on:submit.prevent="handleConfirmation">
                <b-form-group  id="code-input" label="Confirmation Code" >
                    <b-form-input autoFocus type="text" value="text" v-model="confirmationCode" required></b-form-input>
                </b-form-group>
                <b-button block variant="primary" type="submit" :disabled="!isConfirmationCodeValid">Submit</b-button>
            </b-form>
        </b-modal>
    </div>

</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  data () {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: ''
    }
  },
  methods: {

    async handleSubmit (a) {
      console.log('handling signup submit')
      try {
        await Auth.signUp({
          username: this.email,
          password: this.password
        })
        this.$bvModal.show('confirmSignup')
        // alert('User successfully registered. Please login')
        // this.$router.push('/login')
      } catch (error) {
        alert(error.message)
      }
    },

    async handleConfirmation (a) {
      try {
        await Auth.confirmSignUp(this.email, this.confirmationCode)
        alert('Confirmation code successful, please log in')
        this.$router.push('/login')
      } catch (error) {
        alert(error.message)
      }
    }

  },
  computed: {
    isFormInvalid () {
      // log in button disabled if form invalid
      if (this.email.length > 0 && this.password.length > 0 && this.password === this.confirmPassword) {
        return false
      }
      return true
    },
    isConfirmationCodeValid () {
      return this.confirmationCode.length > 0
    }
  }

}
</script>

<style scoped>

#signup {
    width: 500px;
    padding-top: 100px;
    margin: auto;
    border-width: 1px;
}
</style>
