import AWS from 'aws-sdk';
import Chime from 'aws-sdk/clients/chime';
import {
  LogLevel,
  ConsoleLogger,
  DefaultMessagingSession,
  MessagingSessionConfiguration
} from 'amazon-chime-sdk-js';

async function getCurrentUserId() {
  const userDetails = await Auth.currentAuthenticatedUser()
  return userDetails.attributes.sub
}

const designerArnTemplate = process.env.VUE_APP_CHIME_APP_INSTANCE_ARN + '/user/'

export async function connect (onMessage, credentials, userId) {
  const options = {
    region: 'us-east-1',
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  }
  
  const designerArn = designerArnTemplate + userId
  
  const chimeClient = new Chime(options)
  const endpoint = await chimeClient.getMessagingSessionEndpoint().promise()
  
  const configuration = new MessagingSessionConfiguration(
    designerArn,
    null,
    endpoint.Endpoint.Url,
    chimeClient,
    AWS,
  );
  const messagingSession = new DefaultMessagingSession(
    configuration,
    new ConsoleLogger('SDK', LogLevel.INFO),
  );
  const observer = {
    messagingSessionDidStart() { console.log('messaging session start')},
    messagingSessionDidStartConnecting(reconnecting) {console.log('messaging session connecting', reconnecting)},
    messagingSessionDidStop(event) { console.log('messaging session stop', event)},
    messagingSessionDidReceiveMessage(message) {
       onMessage(message)
      }
  }
  messagingSession.addObserver(observer)
  messagingSession.start();
}
