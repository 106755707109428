<template>
    <div>
        <b-row v-for="(item, index) in sections" :key='index' >

            <b-col cols="10" class="section innercontainer">
                <div v-for="(line, lineIndex) in item" :key='lineIndex' >
                    <ViewLine v-bind:items="line" :index='index' :lineIndex='lineIndex'/>
                </div>

            </b-col>

        </b-row>
    </div>
</template>

<script>
import ViewLine from './ViewLine.vue'

export default {
  name: 'ViewData',
  components: {
    ViewLine
  },
  computed: {
    sections () {
      return this.$store.state.sections
    }
  }
}
</script>

<style scoped>

.innercontainer {
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
}

.section {
    background-color: #F7F6F6;
    margin-bottom: 25px;
}

.numberCircle {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    padding: 8px;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font: 32px Arial, sans-serif;
}

</style>
