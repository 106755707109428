<template>
  <div class="d-flex justify-content-center">
    <div class="card">
      <router-link to="/select_template">Create new garment</router-link>
    </div>
    <div class="card">
      <router-link to="/select_garment">Edit garments</router-link>
    </div>
    <div class="card">
      <router-link to="/select_garment_to_view">View garments</router-link>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
.card {
  padding: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* margin: 0 auto; */
  margin: 2rem;
  max-width: 1000px;
}
</style>
