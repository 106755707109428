<template>
  <div>
    <b-form-select v-model='getSelectedStage' :options="options"></b-form-select>
  </div>
</template>

<script>
export default {
  name: 'SelectGarmentStage',
  data () {
    return {
      selected: null,
      options: [
        { value: 'draft', text: 'Draft' },
        { value: 'sample', text: 'Sample' },
        { value: 'production', text: 'Production' }
      ]
    }
  },
  computed: {
    getSelectedStage: {
      get () {
        return this.$store.state.garmentStage
      },
      set (value) {
        this.$store.commit('setGarmentStage', value)
      }
    }
  }
}
</script>

<style scoped>
.custom-select {
    display: inline-block;
    width: 300px;
    margin: auto;
    margin-bottom: 50px;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>
