// Vue build variables loaded from .env.dev or .env.app
// https://cli.vuejs.org/guide/mode-and-env.html#example-staging-mode

const config = {
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: process.env.VUE_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.VUE_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.VUE_APP_IDENTITY_POOL_ID,

  }
}
export default config
