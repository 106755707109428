<template>
    <div class="inputRow">

        <EditableTable class="table" v-if="lineInfo.Heading == 'Measurements'" />
        <div v-if="lineInfo.Heading !== 'Measurements'" class="d-flex inputLabel text-right justify-content-end">
            <SectionHeading v-if="lineInfo.Heading" :markup= "lineInfo.Markup" :heading = "lineInfo.Heading" :units="lineInfo.Units" :required="lineInfo.Required"/>
        </div>
        <input list="size-range-list" :value='lineInfo.Value' v-if="lineInfo.Input_Types && lineInfo.Heading === 'Size Range'" @input="updateValue($event)">
        <input list="seasons-list" :value='lineInfo.Value' v-if="lineInfo.Input_Types && lineInfo.Heading === 'Season'" @input="updateValue($event)"  >

        <input v-if="lineInfo.Input_Types && lineInfo.Input_Types != 'textArea' && lineInfo.Input_Types != 'upload' && lineInfo.Heading !== 'Size Range' && lineInfo.Heading !== 'Season'" @input="updateValue($event)" :value="lineInfo.Value" :type="getInputType" :size="inputSize">
        <textarea v-if="lineInfo.Input_Types == 'textArea'" @input="updateValue($event)" :value="lineInfo.Value"  rows="4" cols="59"></textarea>
        <input v-if="lineInfo.Input_Types == 'upload'" type="file" id="myFile" name="filename" accept="image/png, image/jpeg" @input="getFile">
        <div v-if="getImage">
          <Images :imageCode='lineInfo.fileName'/>
          <b-button v-on:click='deleteImage'>delete image</b-button>
        </div>
        <p v-if='lineInfo.Heading === "Designer"'>{{currentUser}}</p>
        <p v-if='lineInfo.Heading === "Stage"'><SelectGarmentStage /></p>

        <datalist id="size-range-list">
          <option v-for="(sizeRange, index) in sizeRanges" v-bind:key="index" :value='sizeRange'>{{ sizeRange }}</option>
        </datalist>

        <datalist id="seasons-list">
          <option v-for="(season, index) in ['spring', 'summer', 'autumn', 'winter']" v-bind:key="index" :value='season'>{{ season }}</option>
        </datalist>

    </div>
</template>

<script>
import SectionHeading from './SectionHeading.vue'
import Images from './factory/Images.vue'
import EditableTable from '../components/EditableTable.vue'
import { Auth } from 'aws-amplify'
import SelectGarmentStage from './SelectGarmentStage.vue'

export default {
  name: 'LineItem',
  props: ['index', 'lineIndex'],
  components: {
    SectionHeading,
    Images,
    EditableTable,
    SelectGarmentStage
  },
  data () {
    return {
      currentUser: '',
      sectionIndex: this.index,
      sectionLineIndex: this.lineIndex,
      seasonsList: [
        { value: 'spring', text: 'spring' },
        { value: 'summer', text: 'summer' },
        { value: 'autumn', text: 'autumn' },
        { value: 'winter', text: 'winter' },
        { value: 'unspecified', text: 'unspecified' }
      ]
    }
  },
  methods: {
    deleteImage () {
      console.log('Deleting image')
      this.$store.commit('deleteImage', [this.lineInfo.fileName, this.index, this.sectionLineIndex])
    },
    getFile (event) {
      this.$store.commit('addFile', [event.target.files[0], this.index, this.lineIndex])
    },
    getInputType () {
      return this.lineInfo.Input_Types
    },
    updateValue (value) {
      if (this.lineInfo.Heading === 'Size Range') {
        this.$store.commit('setActiveSizeRange', value.target.value)
        this.$store.commit('updateSections', [value.target.value, this.index, this.lineIndex])
      } else {
        this.$store.commit('updateSections', [value.target.value, this.index, this.lineIndex])
      }
    }
  },
  computed: {
    async getUserEmail () {
      const response = await Auth.currentSession()
      return response.idToken.payload.email
    },
    getImage () {
      return this.$store.state.sections[this.index][this.lineIndex].fileName
    },
    savedSizeRange () {
      return this.$store.state.activeSizeRange
    },
    sizeRanges () {
      const sizeRanges = []
      const sizeRangeInfo = this.$store.getters.getSizeRanges
      for (let i = 0; i < sizeRangeInfo.length; i++) {
        sizeRanges.push(sizeRangeInfo[i].sizeRangeName)
      }
      return sizeRanges
    },
    inputSize () {
      switch (this.lineInfo.Input_length) {
        case 'short':
          return 12
        case 'long':
          return 60
        case 'mid':
          return 30
        default:
          return 20
      }
    },

    lineInfo: {
      get () {
        return this.$store.state.sections[this.index][this.lineIndex]
      },
      set (value) {
        this.$store.commit('updateSections', [value, this.index, this.lineIndex])
      }
    }
  },
  async created () {
    const response = await Auth.currentSession()
    this.currentUser = response.idToken.payload.email
  }
}
</script>

<style scoped>

form.form-example {
    display: table;
}

div.form-example {
    display: table-row;
}

input, textarea {
    margin-bottom: 5px;
    margin-left: 10px;
}

label {
    padding-right: 10px;
}

/* .inputRow {
    display: flex;
} */

.inputLabel:not(table) {
    flex: 0 0 230px;
}

.table {
  flex: 0px;
}

@media print {
  .inputRow {
    page-break-before: auto;
  }
}
</style>
