<template>
  <div>
    <MainForm />
    <AssignToFactory />
    <SendEmailCheckbox />
    <SelectGarmentStage />
    <b-button @click='submitDesign'>submit design</b-button>
  </div>
</template>

<script>
import MainForm from '../components/MainForm.vue'
import AssignToFactory from '../components/AssignToFactory.vue'
import SelectGarmentStage from '../components/SelectGarmentStage.vue'

export default {
  name: 'CreateGarment',
  components: {
    MainForm,
    AssignToFactory,
    SelectGarmentStage
  },

  computed: {
    activeSizeRange() {
      console.log("calling activeSizeRange")
      return this.$store.getters.activeSizeRange
    }
  },

  methods: {

    /**
     * listOfSizes = [ {size_1: 4}, {size_2: 5}, ...]
     * returns [4, 5]
     */
    getRowValues (listOfSizes) {
      console.log({ listOfSizes: listOfSizes })
      const sizeValues = listOfSizes.map(size => {
        return size[key]
      })
      return sizeValues
    },

    arrayEquals (a, b) {
      return a.length === b.length &&
        a.every((val, index) => val === b[index])
    },

    validateSizingSteps (rowValues, minStep, maxStep, isPlusSized) {
      // Difference between row values must be minStep <= x <= maxStep
      console.log({isPlusSized: isPlusSized})
      if (isPlusSized){
        maxStep = maxStep * 1.5
      }
      rowValues = rowValues.map(Number)
      let i = 1
      let j = 0
      for (i; i < rowValues.length; i++) {
        const minValidValue = rowValues[j] + minStep
        const maxValidValue = rowValues[j] + maxStep
        if (rowValues[i] < minValidValue || rowValues[i] > maxValidValue) {
          return false
        }
        j++
      }
      return true
    },

    sortAlphaNum (a, b) {
      return a.localeCompare(b, 'en', { numeric: true })
    },

    validateMeasurementsTable () {
      const self = this
      const measurementsTable = this.$store.state.measurements
      const activeSizeRange = this.$store.state.activeSizeRange
      let isPlusSized = false
      if (activeSizeRange.includes('Plus Size')) {
        isPlusSized = true
      }
      const invalidRows = []

      measurementsTable.forEach(function (row, index) {
        if (row.AreasOnDrawing !== 'size_ranges') {
          let sizes = Object.keys(row).filter(
            key => key.includes('size_')
          )
          const sizesObjects = []
          if (sizes.length > 0) {
            sizes.sort(self.sortAlphaNum)

            // Turn the keys into {key: value} pairs
            sizes = sizes.map(key => {
              return { [key]: row[key] }
            })

            let sizeValues = []
            for (const pair of sizes) {
              // Get list of values form {key: value} pairs in sizes
              const keys = Object.keys(pair)
              sizeValues.push(pair[keys[0]])
            }

            sizeValues = sizeValues.filter(n => n) // remove empty strings from size list
            const sortedSizeValues = [...sizeValues].sort(function (a, b) { return a - b })

            

            if (!self.arrayEquals(sizeValues, sortedSizeValues)) {
              let maxStep = row.Maximum_valid_step 
                if (isPlusSized) {
                  maxStep = maxStep * 1.5
                }
              invalidRows.push(row.AreasInCm + ' measurement differences must be between ' + row.Minimum_valid_step + 'cm and ' + maxStep + 'cm')
            } else {
              if (!self.validateSizingSteps(sizeValues, row.Minimum_valid_step, row.Maximum_valid_step, isPlusSized)) {
                let maxStep = row.Maximum_valid_step 
                if (isPlusSized) {
                  maxStep = maxStep * 1.5
                }
                invalidRows.push(row.AreasInCm + ' measurement differences must be between ' + row.Minimum_valid_step + 'cm and ' + maxStep + 'cm')
              }
            }
          }
       }
      })
      return invalidRows
    },

    displayError (type, title, text) {
      this.$fire({
        type: type,
        title: title,
        html: text
      })
    },

    async submitDesign () {
      const invalidMeasurementsTableRows = this.validateMeasurementsTable()
      console.log({ invalidMeasurementsTableRows: invalidMeasurementsTableRows })
      if (invalidMeasurementsTableRows.length !== 0) {
        const invalidRowHTML = `<ul><li>${invalidMeasurementsTableRows.join('</li><li>')}</li></ul>`
        this.displayError('error', 'Invalid measurements', invalidRowHTML)
        return
      }

      try {
        await this.$store.dispatch('postDesign')
      } catch (error) {
        const errorMessage = `<p> ${error} </p>`
        this.displayError('error', 'Techpack submit error', errorMessage)
        return
      }

      if (this.$store.state.failedRequiredFields.length !== 0) {
        var failedRequirementsString = ''
        for (const requirement of this.$store.state.failedRequiredFields) {
          const stringRequirement = `<li>${requirement.sectionHeading} - ${requirement.requirementHeading}</li> `
          failedRequirementsString = failedRequirementsString + stringRequirement
        }
        const errorMessage = 'Required inputs not filled in: ' + failedRequirementsString
        this.displayError('error', 'Requirements error', errorMessage)
        return
      }

      if (this.$store.state.sendEmailtoFactory && this.$store.state.assignedFactory.length == 0) {
        const errorMessage = "Factory notifications can't be send if no factory is specified"
        this.displayError('error', 'Requirements error', errorMessage)
        return
      }

      this.$store.commit('resetLocalState')
      this.$router.push('/')
    }
  }
}

</script>
