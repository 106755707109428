<template>
    <div class="inputRow">
      <div class="d-flex inputLabel text-right justify-content-end">
        <input v-if='editable && lineInfo.Markup === "subtitle"' :placeholder="'Field name'" @input="$emit('input', [$event.target.value, lineIndex])" />
        <SectionHeading v-if="lineInfo.Heading && Object.keys(lineInfo).length > 2" :heading = "lineInfo.Heading" :units='lineInfo.Units' class="text-right"/>
      </div>
      <input v-if="lineInfo.Input_Types && lineInfo.Input_Types !== 'upload'" :type="getInputType()" :size="inputSize" @input="$emit('input', [$event.target.value, lineIndex])">
      <input v-if="lineInfo.Input_Types == 'upload'" type="file" id="myFile" name="filename" @input="getFile">
    </div>
</template>

<script>
import SectionHeading from './SectionHeading.vue'

export default {
  name: 'collapseLine',
  props: ['editable', 'itemName', 'index', 'lineIndex'],
  components: {
    SectionHeading
  },
  methods: {
    getInputType () {
      return this.lineInfo.Input_Types
    },
    getFile (event) {
      // TODO: This needs to be emitted to parent, then add file through store/addFile only when extended form is actually added
      // this.$store.commit('addFile', [event.target.files[0], this.index, this.lineIndex])
      // make sure it's marked locally on the object that a file has been attached
      this.$emit('addFile', [event.target.files[0], this.index, this.lineIndex])
    }
  },
  computed: {
    inputSize () {
      switch (this.lineInfo.Input_length) {
        case 'short':
          return 12
        case 'long':
          return 60
        case 'mid':
          return 30
        default:
          return 20
      }
    },
    lineInfo: {
      get () {
        return this.$store.state.sections[this.index][this.lineIndex]
      }
    }
  }
}
</script>

<style scoped>

input {
    margin-bottom: 5px;
    margin-left: 10px;
}

label {
    padding-right: 10px;
}

.inputRow {
    display: flex;
}

.inputLabel {
    flex: 0 0 230px;
}

</style>
