<template>
  <div>
    <h2 class="section-heading" v-b-toggle="'collapse-section-' + index.toString()">
      {{sectionHeadings[index]}}
      <b-icon-arrows-collapse v-if='visible'></b-icon-arrows-collapse>
      <b-icon-arrows-expand v-else></b-icon-arrows-expand>
    </h2>

    <b-collapse :id="'collapse-section-' + index.toString()" v-model="visible">
        <div class="section innercontainer">
            <div v-for="(line, lineIndex) in section" :key='lineIndex' >
                <LineItem v-bind:items="line" :index='index' :lineIndex='lineIndex'/>
            </div>
            <div v-if="section[0].Extendible">

                <NewProperty :sectionIndex='index' />
            </div>
            <div v-if="section[0].Editable">
                <NewEditableProperty :sectionIndex='index' />
            </div>
        </div>
    </b-collapse>
  </div>
</template>

<script>
import LineItem from './LineItem.vue'
import NewProperty from './NewProperty.vue'
import NewEditableProperty from './NewEditableProperty.vue'

export default {
  name: 'SectionCollapse',
  props: ['section', 'index'],
  data () {
    return {
      visible: false
    }
  },
  components: {
    LineItem,
    NewProperty,
    NewEditableProperty
  },
  computed: {
    sectionHeadings () {
      const sections = this.$store.state.sections
      const sectionsList = []
      for (const section of sections) {
        sectionsList.push(section[0].Heading)
      }
      return sectionsList
    }
  },
  created () {
    if (this.sectionHeadings[this.index] === 'Summary') {
      this.visible = true
    }
  }
}
</script>

<style scoped>
.innercontainer {
    padding: 10px;
    margin-left: 75px;
    margin-right: 75px;
}

.section {
    background-color: #F7F6F6;
    margin-bottom: 25px;
}

.section-heading {
    margin-bottom: 20px;
}
</style>
