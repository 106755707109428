<template>
  <div>
    <p>Select Template</p>
    <div class="search-list">
      <b-input-group size="sm" class="mb-2">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input v-model="searchTerm" type="search" placeholder="Search templates"></b-form-input>
      </b-input-group>
    </div>
    <b-container>
      <ul class="row">
        <li v-for="(template, index) in templateIds" :key='index' class='col-lg list-item'>
          <b-card no-body class="overflow-hidden" style="max-width: 500px; min-width: 500px;">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img :src='template.imageURL' alt="Image" class="rounded-0" style="max-width: 10rem;"></b-card-img>
              </b-col>
              <b-col md="6">
                <b-card-body>
                  <b-card-text>
                    <router-link to="/create_garment" @click.native="setTemplate(template.templateName)">create {{template.templateName}} </router-link>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </li>
      </ul>
    </b-container>

  </div>
</template>

<script>
export default {
  data () {
    return {
      searchTerm: ''
    }
  },
  methods: {
    setTemplate (template) {
      this.$store.commit('resetAssignedFactory')
      this.$store.commit('callSetTemplate', template)
    }
  },
  computed: {

    templateIds () {
      const ids = []
      const filteredIds = []
      this.$store.state.templatesList.forEach((item) => {
        ids.push({
          templateName: item.templateName,
          imageURL: item.vectorImageURL
        })
      })
      if (this.searchTerm === '') {
        return ids
      } else {
        for (const id of ids) {
          if (id.templateName.includes(this.searchTerm)) {
            filteredIds.push(id)
          }
        }
        return filteredIds
      }
    }

  },
  created () {
    this.$store.commit('callLoadTemplates')
  }
}
</script>

<style scoped>

input {
  margin-left: 0px;
}

ul {
  list-style-type: none;
}

.list-item {

}

</style>
