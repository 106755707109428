<template>
    <div class="text-right">
        <h3 v-if="markup == 'title' ">{{ heading }}</h3>
        <h5 v-else-if="markup == 'subtitle' ">{{ heading }}</h5>
        <label v-else-if='units'>{{ heading }} ({{ units }})<span style="color:red" v-if="required">*</span>:</label>
        <label v-else>{{ heading }}<span style="color:red" v-if="required">*</span>:</label>

    </div>
</template>

<script>
export default {
  name: 'SectionHeading',
  props: ['markup', 'heading', 'units', 'required']
}
</script>

<style scoped>
h5 {
    margin-top: 15px;
}
</style>
