<template>
  <div id="login">
        <b-form v-on:submit.prevent="handleSubmit">
                <b-form-group  id="email-input" label="Email" >
                <b-form-input autoFocus type="email" value="email" v-model="email" required></b-form-input>
                </b-form-group>

                <b-form-group id="password-input" label="Password">
                    <b-form-input type="password" v-model="password" required></b-form-input>
                </b-form-group>

                <b-button block variant="primary" type="submit" :disabled="isFormInvalid">Log in</b-button>
        </b-form>
        <!-- Signup button not needed at the moment because clients can't sign up on their own -->
        <!-- <b-button class='mt-4' @click='signup'>Signup</b-button> -->
      </div>
</template>

<script>

import { Auth } from 'aws-amplify'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    async handleSubmit (a) {
      try {
        await Auth.signIn(this.email, this.password)
        alert('Logged in')
        this.$store.commit('logIn')
        const user = await Auth.currentAuthenticatedUser()
        if (user.signInUserSession.accessToken.payload['cognito:groups'][0] === 'factoryusergroup') {
          this.$router.push('/factory_dashboard')
        } else {
          this.$router.push('/')
        }
      } catch (e) {
        alert(e.message)
      }
    },
    signup () {
      this.$router.push('/signup')
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    isFormInvalid () {
      // log in button disabled if form invalid
      if (this.email.length > 0 && this.password.length > 0) {
        return false
      }
      return true
    }
  },
  created () {
    if (this.isLoggedIn) {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
legend {
    font-size: 200%;
}
#login {
    width: 500px;
    padding-top: 100px;
    margin: auto;
    border-width: 1px;
}
</style>
