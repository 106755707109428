<template>
    <div>
        <div v-for="(section, index) in sections" :key='index' >
          <SectionCollapse :section='section' :index='index' />
        </div>
    </div>
</template>

<script>
import SectionCollapse from './SectionCollapse.vue'

export default {
  name: 'MainForm',
  components: {
    SectionCollapse
  },
  computed: {
    sections () {
      return this.$store.state.sections
    }
  }
}
</script>
