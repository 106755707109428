<template>
  <div>
    <router-link to="/select_garment_to_view">View garments</router-link>
  </div>
</template>

<script>

export default {
  name: 'FactoryDashboard'
}
</script>
