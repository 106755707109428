<template>
  <!--
    Updates to measurements in the form are now handled only using two-bay binding with v-model.
    This bypasses the mutations and manipulates the store state directly, something not recommended in
    strict mode (see https://vuex.vuejs.org/guide/forms.html
    The previous approach of using @input and @blur handlers was using the data value instead of the event
    value to update the store state in the `updateMeasurements` mutation. The resulting effect was that
    sometimes the data value was not yet up to date and the measurements were not always updated.
    It seems like the best way to solve this is to use `:value` instead of `v-model` and use
    mutations that take the latest value from the event.
  --->
  <div class='table-container'>
    <b-table striped hover :items="getMeasurements" :fields='fields' small primary-key="pKey" >
        <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
          <b-form-input v-if="field.key === 'Description'" v-model="data.item.Description" size="m" :key="index" />
          <b-form-input v-else-if="field.key === 'Tolerance'" :placeholder="'Add tolerance'" v-model="data.item[field.key]" :key="index" />
          <b-form-input v-else-if="field.key === 'AreasInCm' && data.index >= defaultTableRows" :placeholder="'Add area in cm'" v-model="data.item[field.key]" size="m" :key="index" />
          <b-form-input v-else-if="field.key === 'AreasOnDrawing' && data.index >= defaultTableRows" :placeholder="'Add area on drawing'" v-model="data.item[field.key]" size="m" :key="index" />
          <b-form-input v-else-if="field.editable" :key="index" v-model="data.item[field.key]" type="number" step='0.1' />
          <span :key="index" v-else >{{data.value}}</span>
        </template>
      </b-table>
      <b-button @click='addRowToTable'>Add row</b-button>
  </div>
</template>

<script>

export default {
  name: 'EditableTable',
  data () {
    return {
      defaultTableRows: ''
    }
  },
  methods: {
    /**
     * This mutation is no longer used. See the template comment above.
     * TODO: Resolve the two-way state binding as described.
     */
    updateMeasurements (row) {
      row.index += this.sizeRanges.length
      this.$store.commit('updateMeasurements', [row.value, row.index, row.field.key])
    },
    setDefaultTableRows () {
      return this.computeDefaultTableRows
    },
    addRowToTable () {
      this.$store.commit('addRowToTable')
    }
  },

  computed: {
    activeSizeRange () {
      const activeSizeRangeName = this.$store.getters.getActivesizeRange
      let i = 0
      for (i; i < this.sizeRanges.length; i++) {
        if (this.sizeRanges[i].sizeRangeName === activeSizeRangeName) {
          return i
        }
      }
      return 0
    },
    sizeRanges () {
      return this.$store.getters.getSizeRanges
    },
    computeDefaultTableRows () {
      return this.getMeasurements.length
    },
    getMeasurements () {
      const measurements = this.$store.state.measurements
      const newMeasurements = []
      for (let i = 0; i < measurements.length; i++) {
        if (measurements[i].AreasOnDrawing !== 'size_ranges') {
          measurements[i].pKey = i
          newMeasurements.push(measurements[i])
        }
      }
      return newMeasurements
    },
    fields () {
      let numberOfSizes = 0
      const sizeRange = this.sizeRanges[this.activeSizeRange]
      Object.keys(sizeRange).forEach(function (key) {
        if (!['sizeRangeName', 'AreasOnDrawing'].includes(key)) {
          numberOfSizes += 1
        }
      })

      const fields = [
        { key: 'AreasOnDrawing', label: 'Area on drawing' },
        { key: 'AreasInCm', label: 'Area' },
        { key: 'Description', label: 'Description', editable: true },
        { key: 'Tolerance', label: 'Tolerance (cm)', editable: true }
      ]

      for (let i = 1; i <= numberOfSizes; i++) {
        const str = i.toString()
        const newSize = 'size_' + str
        const newField = {
          key: newSize,
          label: sizeRange[newSize].toString(),
          tdClass: 'inputBox',
          editable: true
        }
        console.log({ SizeLabel: sizeRange[newSize].toString() })
        fields.push(newField)
      }
      return fields
    }
  },
  created () {
    this.defaultTableRows = this.setDefaultTableRows()
  }
}

</script>

<style>

/* .table-container {
  padding: 0px 75px 0px 75px;
} */

.inputBox {
  max-width: 90px;

}

</style>
