import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import { store } from './store/store'
import UUID from 'vue-uuid'
import VueSimpleAlert from 'vue-simple-alert'
import Rollbar from 'rollbar'

// Import global css
import '../assets/css/global.css'
import router from './router'

// Auth/Amplify
import '@aws-amplify/ui-vue'
import Amplify from 'aws-amplify'
import config from './config'

import Chat from 'vue-beautiful-chat'
Vue.use(Chat)

Vue.config.productionTip = false
Vue.use(UUID)
Vue.use(VueSimpleAlert)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.prototype.$rollbar = new Rollbar({
  accessToken: '7b9122c492d7498285656c22de5529bd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: '1.0.0',
    environment: process.env.VUE_APP_STAGE
  },
  onSendCallback: (isUncaught, args, payload) => {
    // Add the Vuex store state to the payload for errors
    if (['error', 'critical'].includes(payload.level)) {
      try {
        payload.store = { ...store.state }
      } catch (error) {
        // Catch in case any issue computing state results in a failure to report to Rollbar
        console.error({ error })
      }
    }
  }
})

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err)
  throw err // rethrow
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
})

Vue.prototype.$Amplify = Amplify

new Vue({
  store,
  router,
  render: h => h(App),
  watch: {
    '$route'(to, from) {
      this.$rollbar.info('Route changed from ' + from.path + ' to ' + to.path);
    }
  }
}).$mount('#app')
