
<template>
  <div id="app">
    <div id="nav">
      <Navbar :userType="userType"/>
      <b-breadcrumb :items="breadcrumbs" v-if="currentRouteName !== 'Home'"></b-breadcrumb>
    </div>
    <router-view/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import { Auth } from 'aws-amplify'

export default {
  components: {
    Navbar
  },
  data () {
    return {
      userType: ''
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    },
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    breadcrumbs () {
      if (this.$store.state.userGroup == 'factoryusergroup') {
        return this.$route.meta.factoryBreadcrumbs
      }
      return this.$route.meta.breadcrumbs
    }
  },

  async created () {
    try {
      await Auth.currentSession()

      const user = await Auth.currentAuthenticatedUser()
      const group = user.signInUserSession.accessToken.payload['cognito:groups'][0]
      this.$rollbar.configure({
        payload: {
          person: {
            id: user.username, // required
            username: user.username,
            email: user.attributes.email,
            group
          }
        }
      });
      this.$store.commit('setUserGroup', group)
      this.userType = group
      // console.log(user.signInUserSession.accessToken.payload['cognito:groups'])
      this.$store.commit('logIn', group)
      if (group != 'designerusergroup') {
        this.$router.push('/factory_dashboard')
      }
    } catch (error) {
      if (error !== 'No current user') {
        alert(error)
      }
    }

    if (!this.isLoggedIn) {
      this.$router.push('/login')
    }
  }
}
</script>
