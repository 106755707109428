<template>
    <div>
        <div>
            <b-button v-b-toggle="'collapse'+sectionIndex" class="ml-5 ">Add new {{ sectionHeading }}</b-button>
        </div>

        <b-collapse :id="'collapse'+sectionIndex" class="mt-2">
            <form v-for="(line, newIndex) in section" :key='newIndex'>
                <CollapseLine v-on:addFile='addFileToComponent' :editable='true' v-on:input='updateNewSection' :itemName='itemName' v-bind:items="line" :index='sectionIndex' :lineIndex='newIndex+1'/>
            </form>
            <input type='submit' @click='addNewSectionItem' value='add item'>
        </b-collapse>
    </div>

</template>

<script>
import CollapseLine from './CollapseLine.vue'
import { uuid } from 'vue-uuid'

export default {
  name: 'NewEditableProperty',

  components: {
    CollapseLine
  },

  props: ['sectionIndex'],

  data () {
    return {
      itemName: '',
      section: [],
      newSection: [],
      id: uuid.v1(),
      fileData: [],
      sectionHeading: 'Section Heading'
    }
  },

  methods: {

    addFileToComponent (payload) {
      this.fileData = payload // [file, sectionIndex, lineIndex]
      const lineIndex = payload[2]
      this.newSection[lineIndex].isFile = true
    },

    updateNewSection (collapseInput) {
      const input = collapseInput[0]
      const lineIndex = collapseInput[1] - 1 // Accounts for subsection being section with [0](heading) removed
      this.newSection[lineIndex].Value = input
      if (lineIndex === 0) {
        this.newSection[lineIndex].Heading = input
      }
    },

    addNewSectionItem () {
      const unboundNewSection = JSON.parse(JSON.stringify(this.newSection))
      this.$store.dispatch('addNewSectionItem', [unboundNewSection, this.sectionIndex, this.fileData])
      // reset section so inputs are blank
      this.id = uuid.v1()
    },

    resetExtendibleForm () {
      // resets form input to blank
      this.newSection = JSON.parse(JSON.stringify(this.getNewSection)) // string to json to copy, not bind
      this.section = JSON.parse(JSON.stringify(this.getNewSection)) // string to json to copy, not bind
    }
  },

  computed: {
    getNewSection () {
      return this.$store.getters.getEmptySubSection(this.sectionIndex)
    },

    getSectionHeading () {
      return this.$store.getters.getSectionHeading(this.sectionIndex)
    }
  },

  created () {
    this.resetExtendibleForm()
    this.sectionHeading = this.getSectionHeading
  }
}
</script>
