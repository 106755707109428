<template>
  <div class="assign-to-factory">
    <h3>Assign techpack to factory</h3>
    <div class="d-flex justify-content-center">
      <p>factory id:</p>
      <input type="text" @input="updateValue($event)" :value="factoryValue">
  </div>
  </div>
</template>

<script>
export default {
  name: 'AssignToFactory',
  methods: {
    updateValue (event) {
      this.$store.commit('setAssignedFactory', event.target.value)
    }
  },
  computed: {
    factoryValue () {
      return this.$store.state.assignedFactory
    }
  }
}
</script>

<style scoped>
.assign-to-factory {
  margin: 30px 0px 30px 0px;
}
</style>
