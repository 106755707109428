<template>
    <div>
        <div>
            <b-button
              :class="visible ? null : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'"
              :aria-controls="'collapse' + sectionIndex"
              @click='autoAddAdditonalImagesFormExtension'
            >
              Add new {{ section[0].Heading }}
            </b-button>
        </div>

        <b-collapse :id="'collapse'+sectionIndex" class="mt-2" :key='id' v-model='visible'>
            <form v-for="(line, newIndex) in section" :key='newIndex' ref='newIndex' >
                <CollapseLine v-on:input='updateNewSection' :itemName='itemName' v-bind:items="line" :index='sectionIndex' :lineIndex='newIndex'/>
            </form>
            <input type='submit' @click='addNewSectionItem' value='add item'>
        </b-collapse>
    </div>

</template>

<script scoped>
import CollapseLine from './CollapseLine.vue'
import { uuid } from 'vue-uuid'

export default {
  name: 'NewProperty',
  components: {
    CollapseLine
  },
  props: ['sectionIndex'],
  data () {
    return {
      itemName: '',
      section: [],
      newSection: [],
      id: uuid.v1(),
      visible: false

    }
  },

  methods: {
    updateNewSection (collapseInput) {
      const input = collapseInput[0]
      const lineIndex = collapseInput[1]
      this.newSection[lineIndex].Value = input
    },

    addNewSectionItem () {
      const unboundNewSection = JSON.parse(JSON.stringify(this.newSection))
      this.$store.dispatch('addNewSectionItem', [unboundNewSection, this.sectionIndex])
      // reset section so inputs are blank

      this.id = uuid.v1()
    },

    resetExtendibleForm () {
      // resets form input to blank
      this.newSection = JSON.parse(JSON.stringify(this.getNewSection)) // string to json to copy, not bind
      this.section = JSON.parse(JSON.stringify(this.getNewSection)) // string to json to copy, not bind
    },

    autoAddAdditonalImagesFormExtension () {
      if (this.section[0].Heading !== 'Additional images') {
        this.visible = !this.visible
      } else {
        this.addNewSectionItem()
      }
    }
  },

  computed: {
    getNewSection () {
      return this.$store.getters.getEmptySection(this.sectionIndex)
    }
  },

  created () {
    this.resetExtendibleForm()
  }

}
</script>
