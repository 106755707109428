<template>
    <div>
    <p>Select garment</p>
    <div class="search-list">
      <b-input-group size="sm" class="mb-2">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input v-model="searchTerm" type="search" placeholder="Search garments"></b-form-input>
      </b-input-group>
    </div>

    <b-container>
      <ul class="row">
        <li v-for="(garments, index) in garmentNames" :key='index' class='col-lg list-item'>
            <b-card no-body class="overflow-hidden" style="max-width: 500px; min-width: 500px;">
              <b-row no-gutters>
                <b-col md="6">
                </b-col>
                <b-col md="6">
                  <b-card-body>
                    <b-card-text>
                      <router-link to="/edit_garment" @click.native="setGarment(garments.id)">Edit {{garments.name}} </router-link>
                      <p>Garment size range: {{garments.size_range}}</p>
                      <p>Garment code: {{garments.code}}</p>
                      <p>Designer: {{garments.designerEmail}}</p>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
        </li>
      </ul>
    </b-container>

  </div>
</template>

<script>
export default {
  data () {
    return {
      searchTerm: ''
    }
  },
  methods: {
    setGarment (garmentId) {
      this.$store.commit('resetChimeChannelArn')
      this.$store.commit('resetAssignedFactory')
      this.$store.dispatch('setGarment', garmentId)
    }
  },
  computed: {
    garmentNames () {
      const garments = []
      const filteredGarments = []
      let details = []
      this.$store.state.garmentList.forEach((item) => {
        details = { name: item.name, id: item.id, code: item.code, designerEmail: item.designer_email }
        garments.push(details)
      })
      if (this.searchTerm === '') {
        return garments
      } else {
        for (const garment of garments) {
          if (garment.name.includes(this.searchTerm)) {
            filteredGarments.push(garment)
          }
        }
        return filteredGarments
      }
    }

  },
  created () {
    this.$store.commit('loadGarments')
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>
