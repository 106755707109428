<template>
  <div>
    <b-button @click='triggerError'>trigger Error</b-button>
  </div>
</template>

<script>

export default {
  name: 'Debug',
  methods: {
    async triggerError () {
      throw new Error(`Debug error for troubleshooting only ${Date.now()}`)
    }
  }
}

</script>
